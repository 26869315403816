/*------------------------------------
  Font Implementation
------------------------------------*/
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=9ebd8991-e709-4a98-a3d8-c87af7e26f56&fontids=1475730,1475746,1475766,1475778,1571169");
@font-face {
  font-family: "Frutiger 45 Light";
  src: url("../../../App_Fonts/1475730/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2") format("woff2"), url("../../../App_Fonts/1475730/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff") format("woff");
}
@font-face {
  font-family: "Frutiger 65 Bold";
  src: url("../../../App_Fonts/1475746/8c92eb68-ce37-4962-a9f4-5b47d336bd1c.woff2") format("woff2"), url("../../../App_Fonts/1475746/9bdfdcac-bbd4-46a9-9280-056c9a4438a2.woff") format("woff");
}
@font-face {
  font-family: "Frutiger 47 Light Condensed";
  src: url("../../../App_Fonts/1475766/d081ebc4-72ee-49ad-94a1-b3cd3e01e2a1.woff2") format("woff2"), url("../../../App_Fonts/1475766/e4b55b70-bcab-4701-b1ce-3cd09b3c8077.woff") format("woff");
}
@font-face {
  font-family: "Frutiger 67 Bold Condensed";
  src: url("../../../App_Fonts/1475778/4c4ee705-e593-4456-988d-03a5a2594534.woff2") format("woff2"), url("../../../App_Fonts/1475778/e728739a-5d86-4213-9f4f-73394eff3d19.woff") format("woff");
}
@font-face {
  font-family: "Frutiger 48 Light Condensed Italic";
  src: url("../../../App_Fonts/1571169/dd14f86b-6832-4698-a69d-50818bb5d389.woff2") format("woff2"), url("../../../App_Fonts/1571169/100c0386-b64c-4c85-9172-b79785f4dcd2.woff") format("woff");
}
/*COLORS*/
/*@NewLightGray: lighten(@Gray, 10%);*/
/*@Gray: #e2e2e2;*/
/*BREAKPOINTS*/
.umb-grid .grid-section > div {
  margin-bottom: 15px;
}
.umb-grid .grid-section > div .column .box {
  margin-bottom: 15px;
  padding: 20px;
}
.umb-grid .grid-section > div .column .box_woTopPad {
  padding-top: 0px !important;
}
.umb-grid .grid-section > div .column .box_woRightPad {
  padding-right: 0px !important;
}
.umb-grid .grid-section > div .column .box_woBotPad {
  padding-bottom: 0px !important;
}
.umb-grid .grid-section > div .column .box_woLeftPad {
  padding-left: 0px !important;
}
.umb-grid .grid-section > div .column .box_wLeftPad {
  padding-left: 33px !important;
}
a {
  /*color: @MainColor;*/
  color: #434343;
}
a:hover {
  /*color: @MainColorLighten;*/
  color: #00519e;
}
a:focus {
  /*color: @MainColorLighten;*/
  color: #00519e;
}
.MengLogo {
  width: 150px;
}
@media only screen and (min-width: 992px) {
  .umb-grid .grid-section > div .column .box_wLeftPad {
    padding-left: 49px !important;
  }
}
html,
body {
  font-family: "Frutiger 45 Light";
  margin: 0;
}
.bg-light-gray {
  background-color: #f4f4f4;
}
.bg-gray {
  background-color: #c3c6c8;
}
.bg-dark-gray {
  background-color: #808080;
}
.fg-light-gray {
  color: #f4f4f4;
}
.fg-gray {
  color: #c3c6c8;
}
.fg-dark-gray {
  color: #808080;
}
.only-desktop {
  display: none !important;
}
@media only screen and (min-width: 992px) {
  .only-desktop {
    display: block !important;
  }
}
@media only screen and (min-width: 992px) {
  .only-mobile {
    display: none !important;
  }
}
.cbp:not(.cbp-ready) .cbp-item {
  opacity: 0;
}
#Footer {
  /*background: url(/media/vm1ct3ti/meng_footer.png);*/
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -15px;
  position: relative;
  /* Footer */
  /* Legal Footer */
}
#Footer:before {
  background-color: rgba(255, 255, 255, 0.5);
  content: "";
  height: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
#Footer #contacts-section .container {
  padding-left: 48px;
}
#Footer #contacts-section .container .social-media h2 {
  font-size: 16px;
  font-weight: 600;
}
#Footer #contacts-section .container .social-media h2 .fa {
  font-size: 20px;
  margin-left: 15px;
  text-decoration: none;
}
#Footer #contacts-section .container .social-media h2 .fa:hover {
  color: #fff;
  cursor: pointer;
}
#Footer #contacts-section .container .sitemap-column a {
  color: #555;
}
#Footer #contacts-section .container .sitemap-column a:hover {
  text-decoration: none;
  color: #00519e;
}
#Footer #contacts-section .container .sitemap-column ul {
  padding: 0;
}
#Footer #contacts-section .container .sitemap-column ul li {
  list-style: none;
}
#Footer footer .container {
  padding-left: 48px;
}
@media only screen and (min-width: 992px) {
  #Footer {
    /* Footer */
    /* Legal Footer */
  }
  #Footer #contacts-section .container {
    padding-left: 64px;
  }
  #Footer footer .container {
    padding-left: 64px;
  }
}
header {
  background-color: #fff;
  margin-bottom: -15px;
}
header .navbar-top-bar {
  padding-bottom: 0 !important;
}
header .navbar-top-bar .container {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
header .navbar-top-bar .container .member-profile {
  margin-left: auto;
  white-space: nowrap;
}
header .navbar-top-bar .container .search-and-cart {
  position: relative !important;
  right: 0 !important;
  /*margin-left: auto;*/
  width: 100%;
}
header .u-header__section {
  padding: 0 !important;
}
header .tooltiptext {
  font-family: "Frutiger 45 Light";
  font-size: 13px;
  line-height: 20.8px;
  letter-spacing: 0px;
  visibility: hidden;
  width: 150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}
header .navbar-top-bar,
header .navbar {
  padding-bottom: 25px;
}
header .navbar-top-bar .navbar-brand,
header .navbar .navbar-brand {
  padding-top: 25px;
  padding-left: 20px;
}
header .navbar-top-bar .navbar-brand img,
header .navbar .navbar-brand img {
  width: 150px;
}
header .navbar-top-bar .navbar-account,
header .navbar .navbar-account {
  position: absolute;
  right: 40px;
  top: 0;
  font-family: "Frutiger 45 Light";
  font-size: 16px;
  letter-spacing: 1.92px;
  opacity: 0.7;
}
header .navbar-top-bar .navbar-account .tooltiptext,
header .navbar .navbar-account .tooltiptext {
  top: 100%;
  left: 50%;
  margin-left: -75px;
}
header .navbar-top-bar .navbar-account:hover .tooltiptext,
header .navbar .navbar-account:hover .tooltiptext {
  visibility: visible;
  opacity: 0.7;
}
header .navbar-top-bar .navbar-account a:hover,
header .navbar .navbar-account a:hover {
  text-decoration: none;
  /*color: @MainColor;*/
}
header .navbar-top-bar .subnav,
header .navbar .subnav {
  *zoom: 1;
  bottom: 0;
  position: absolute;
  right: 28px;
}
header .navbar-top-bar .subnav::before,
header .navbar .subnav::before,
header .navbar-top-bar .subnav::after,
header .navbar .subnav::after {
  content: " ";
  display: table;
}
header .navbar-top-bar .subnav::after,
header .navbar .subnav::after {
  clear: both;
}
header .navbar-top-bar .subnav .search,
header .navbar .subnav .search {
  float: left;
  text-transform: uppercase;
  display: inline-flex;
}
header .navbar-top-bar .subnav .search #searchInput,
header .navbar .subnav .search #searchInput {
  width: 100%;
  background-color: #fff;
}
header .navbar-top-bar .subnav .search label,
header .navbar .subnav .search label {
  font-family: "Frutiger 45 Light";
  font-size: 16px;
  letter-spacing: 1.92px;
  margin-right: 5px;
}
header .navbar-top-bar .subnav .search .searchSVG,
header .navbar .subnav .search .searchSVG {
  position: relative;
  top: 10px;
  right: 10px;
}
header .navbar-top-bar .subnav .language-selector,
header .navbar .subnav .language-selector {
  float: left;
  margin-right: 15px;
  text-transform: uppercase;
  padding-top: 10px;
}
header .navbar-top-bar .subnav .language-selector .tooltiptext,
header .navbar .subnav .language-selector .tooltiptext {
  top: unset;
  bottom: 76%;
  left: 57%;
  text-transform: none;
}
header .navbar-top-bar .subnav .language-selector .disabled,
header .navbar .subnav .language-selector .disabled {
  color: #555;
  opacity: 0.7;
}
header .navbar-top-bar .subnav .language-selector .disabled:hover .tooltiptext,
header .navbar .subnav .language-selector .disabled:hover .tooltiptext {
  visibility: visible;
  opacity: 0.7;
}
header .navbar-top-bar .subnav .language-selector .lang,
header .navbar .subnav .language-selector .lang {
  font-family: "Frutiger 45 Light";
  font-size: 16px;
  letter-spacing: 1.92px;
}
header .navbar-top-bar .subnav .language-selector .lang.selected,
header .navbar .subnav .language-selector .lang.selected {
  color: #00519e;
}
header .navbar-top-bar .subnav .language-selector .lang:hover,
header .navbar .subnav .language-selector .lang:hover {
  color: #00519e;
  text-decoration: none;
}
header .navbar-top-bar .subnav .language-selector .lang:first-child:after,
header .navbar .subnav .language-selector .lang:first-child:after {
  content: ' | ';
  color: #00519e;
}
header .navbar-top-bar .subnav .cart,
header .navbar .subnav .cart {
  position: relative;
  float: left;
  padding-top: 10px;
}
header .navbar-top-bar .subnav .cart .svgIcon,
header .navbar .subnav .cart .svgIcon {
  /*margin-bottom: 8px;*/
  padding-right: 15px;
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}
header .navbar-top-bar .subnav .cart .svgIcon .tooltiptext,
header .navbar .subnav .cart .svgIcon .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}
header .navbar-top-bar .subnav .cart .svgIcon:hover .tooltiptext,
header .navbar .subnav .cart .svgIcon:hover .tooltiptext {
  visibility: visible;
  opacity: 0.7;
}
header .navbar-top-bar .subnav .cart .cart-product-count,
header .navbar .subnav .cart .cart-product-count {
  position: absolute;
  top: 0;
  right: 0;
}
header .navbar-top-bar .subnav .cart .cart-product-count .basket-bar-counter,
header .navbar .subnav .cart .cart-product-count .basket-bar-counter {
  padding: 0.1em 0.2em;
  background-color: #00519e;
  border-radius: 50px;
  color: white;
}
@media only screen and (min-width: 992px) {
  header .navbar-top-bar {
    display: none !important;
  }
}
header nav.navbar #navBar .firstNavRow .secondNavRow .thirdNavRow {
  top: -4px;
}
header nav.navbar #navBar .navbar-nav {
  justify-content: space-between;
  width: 100%;
}
header nav.navbar #navBar .navbar-nav .nav-item {
  text-transform: uppercase;
  padding-top: 2px;
  padding-bottom: 3px;
}
header nav.navbar #navBar .navbar-nav .nav-item ul {
  border-color: #00519e !important;
  background-color: #f4f4f4;
}
header nav.navbar #navBar .navbar-nav .nav-item ul .dropdown-item {
  padding: unset;
}
header nav.navbar #navBar .navbar-nav .nav-item ul .level3-dropdown-item {
  padding: .25rem 1.5rem;
}
header nav.navbar #navBar .navbar-nav .nav-item a {
  color: #434343;
  padding-top: 0;
  padding-bottom: 0;
  font-family: "Frutiger 45 Light";
  font-size: 16px;
  letter-spacing: 1.92px;
  font-size: 15px;
}
header nav.navbar #navBar .navbar-nav .nav-item a:hover {
  text-decoration: none;
  color: #00519e;
}
header nav.navbar #navBar .navbar-nav .nav-item a:hover::after {
  color: #00519e;
}
@media only screen and (min-width: 992px) {
  header nav.navbar {
    background-color: rgba(150, 150, 150, 0.1);
    padding: 0;
  }
  header nav.navbar #navBar .firstNavRow .nav-item:first-child {
    margin-left: 50px !important;
  }
  header nav.navbar #navBar .firstNavRow .nav-item:last-child {
    margin-right: 25px !important;
  }
  header nav.navbar #navBar .firstNavRow .secondNavRow .nav-item:first-child {
    margin-left: 1.42857rem !important;
  }
  header nav.navbar #navBar .firstNavRow .secondNavRow .nav-item:last-child {
    margin-right: 1.42857rem !important;
  }
  header nav.navbar #navBar .firstNavRow .secondNavRow .thirdNavRow {
    top: -4px;
  }
  header nav.navbar #navBar .navbar-nav {
    justify-content: space-between;
    width: 100%;
  }
  header nav.navbar #navBar .navbar-nav .nav-item {
    text-transform: uppercase;
    padding-top: 2px;
    padding-bottom: 3px;
  }
  header nav.navbar #navBar .navbar-nav .nav-item ul {
    border-color: #00519e !important;
    background-color: #f4f4f4;
  }
  header nav.navbar #navBar .navbar-nav .nav-item ul .dropdown-item {
    padding: .25rem 1.5rem;
  }
  header nav.navbar #navBar .navbar-nav .nav-item a {
    color: #434343;
    padding-top: 0;
    padding-bottom: 0;
    font-family: "Frutiger 45 Light";
    font-size: 16px;
    letter-spacing: 1.92px;
    font-size: 13px;
  }
  header nav.navbar #navBar .navbar-nav .nav-item a:hover {
    text-decoration: none;
  }
}
@media only screen and (min-width: 1200px) {
  header nav.navbar #navBar .navbar-nav .nav-item a {
    font-size: 15px;
  }
}
#ProductFinder .search-result {
  border: none;
}
#ProductFinder .search-result .cbp-item img {
  height: 175px;
  width: 300px;
}
#ProductFinder .filter-wrapper input {
  background-color: #e9eaeb;
  border: 0;
}
#ProductFinder .filter-wrapper input:focus {
  border-color: #00519e;
}
#ProductFinderTeaser .k-input {
  background-color: transparent;
}
#ProductFinderTeaser .filter-wrapper .form-group .form-control {
  background-color: #e9eaeb;
  color: #000;
  border: 0;
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
#ProductFinderTeaser .filter-wrapper .form-group .form-control:focus {
  border-color: #00519e;
}
#ProductFinderTeaser .filter-wrapper .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body #ProductDetail.background {
  background-color: #f7f7f7;
}
body #ProductDetail .productDetailBreadCrumb {
  padding-left: 30px;
}
body #ProductDetail .productDetailBreadCrumb .active {
  font-weight: bold;
}
body #ProductDetail .productDetailBreadCrumb a:hover {
  text-decoration: none;
}
body #ProductDetail .productImage {
  margin-bottom: 20px;
  position: relative;
}
body #ProductDetail .productImage .topRow {
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
body #ProductDetail .productImage .topRow .backButton {
  float: left;
  color: #434343;
  padding: 7px 30px;
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
body #ProductDetail .productImage .topRow .backButton a {
  color: #434343;
}
body #ProductDetail .productImage .topRow .backButton a:hover {
  text-decoration: none;
  color: #00519e;
}
body #ProductDetail .productImage .topRow .iconsRight {
  float: right;
}
body #ProductDetail .productImage .topRow .iconsRight .svgIcon.cartSVG {
  padding: 0;
  width: 104px;
}
body #ProductDetail .productImage .topRow .iconsRight .svgIcon {
  padding-right: 15px;
  position: relative;
  display: inline-block;
}
body #ProductDetail .productImage .topRow .iconsRight .svgIcon .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}
body #ProductDetail .productImage .topRow .iconsRight .svgIcon:hover .tooltiptext {
  visibility: visible;
  opacity: 0.7;
}
body #ProductDetail .productImage .topRow .iconsRight .fa {
  font-size: 27px;
  padding: 3px 5px;
  color: #434343;
}
body #ProductDetail .productImage .topRow .iconsRight .fa:last-of-type {
  padding-right: 10px;
}
body #ProductDetail .productImage .productImagePhotographer {
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding-left: 5px;
  bottom: 0;
}
body #ProductDetail .productImage img {
  max-width: 100%;
}
body #ProductDetail .productInformation {
  padding: 0px 20px 20px;
}
body #ProductDetail .productInformation h1 {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 23px;
  letter-spacing: 0.46px;
  margin-bottom: 3px;
  line-height: 1em;
}
body #ProductDetail .productInformation .productDescription {
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
body #ProductDetail .productInformation .productDescription h3 {
  font-family: "Frutiger 47 Light Condensed";
  font-size: 18px;
  letter-spacing: 0.36px;
  margin-bottom: 1em;
}
body #ProductDetail .productInformation .svgIcon {
  position: relative;
  display: block;
  margin-bottom: 8px;
}
body #ProductDetail .productInformation .fa {
  color: #434343;
  font-size: 25px;
  padding-left: 2px;
}
body #ProductDetail .productInformation .dataSheetsWrapper,
body #ProductDetail .productInformation .productReferencesWrapper {
  padding: 15px 0;
}
body #ProductDetail .productInformation .dataSheetsWrapper svg,
body #ProductDetail .productInformation .productReferencesWrapper svg {
  position: relative;
  width: 25px;
  height: auto;
}
body #ProductDetail .productInformation .dataSheetsWrapper .dataSheets,
body #ProductDetail .productInformation .productReferencesWrapper .dataSheets,
body #ProductDetail .productInformation .dataSheetsWrapper .productReferences,
body #ProductDetail .productInformation .productReferencesWrapper .productReferences {
  padding: 5px 0 8px;
  border-top: 1px solid #e9eaeb;
  border-bottom: 1px solid #e9eaeb;
}
body #ProductDetail .productInformation .dataSheetsWrapper .dataSheets a,
body #ProductDetail .productInformation .productReferencesWrapper .dataSheets a,
body #ProductDetail .productInformation .dataSheetsWrapper .productReferences a,
body #ProductDetail .productInformation .productReferencesWrapper .productReferences a {
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
body #ProductDetail .productInformation .dataSheetsWrapper .dataSheets a:hover,
body #ProductDetail .productInformation .productReferencesWrapper .dataSheets a:hover,
body #ProductDetail .productInformation .dataSheetsWrapper .productReferences a:hover,
body #ProductDetail .productInformation .productReferencesWrapper .productReferences a:hover {
  text-decoration: none;
}
body #ProductDetail .productInformation .dataSheetsWrapper .productReferences a,
body #ProductDetail .productInformation .productReferencesWrapper .productReferences a {
  text-decoration: underline;
}
body #ProductDetail .productInformation .property-grid .prop-row {
  display: inline-table;
  width: 100%;
  border-bottom: 1px solid #c3c6c8;
}
body #ProductDetail .productInformation .property-grid .prop-row .prop-name,
body #ProductDetail .productInformation .property-grid .prop-row .prop-value {
  display: inline-block;
  padding: 5px 0;
  float: left;
}
body #ProductDetail .productInformation .property-grid .prop-row .prop-name {
  width: 30%;
}
body #ProductDetail .productInformation .property-grid .prop-row .prop-value {
  width: 70%;
  padding-left: 15px;
  padding-right: 15px;
}
body #ProductDetail .productInformation .property-grid .prop-delete {
  border-bottom: none !important;
}
body #ProductDetail .scroll-button-container {
  position: fixed;
  display: inline-block;
  width: 100%;
  height: 110px;
  left: 0;
  bottom: 0;
  background-color: #f7f7f7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: transform 0.1s ease-in-out;
}
body #ProductDetail .scroll-button-container > .container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
body #ProductDetail .scroll-button-container > .container h3 {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 16px;
  margin-bottom: 3px;
  line-height: 1em;
}
body #ProductDetail .scroll-button-container > .container .product-info .info-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
body #ProductDetail .scroll-button-container > .container .product-info .info-container h3 {
  width: 50%;
  float: left;
}
body #ProductDetail .scroll-button-container > .container .product-info .info-container h3:nth-of-type(2) {
  text-align: right;
}
body #ProductDetail .referenceImages .swiper-slide .fixedHeight {
  height: auto;
}
body #ProductDetail .referenceImages .swiper-slide .fixedHeight img {
  width: 100%;
  width: stretch;
}
body #ProductDetail .referenceImages .swiper-pagination-bullet {
  background-image: url('../../Media/Custom/inaktiv_TransBg.png');
  height: 12px;
  width: 12px;
  background-color: unset;
  opacity: 1;
}
body #ProductDetail .referenceImages .swiper-pagination-bullet-active {
  background-image: url('../../Media/Custom/aktiv_TransBg.png');
}
body #ProductDetail .referenceImages .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 18px;
  /*     left: 70%;
                width: unset;*/
  left: 5px;
  width: 100%;
}
body #ProductDetail .referenceImages .swiper-container .fa {
  position: absolute;
  bottom: 0;
  z-index: 11;
  font-size: 19px;
  color: #434343;
  cursor: pointer;
}
body #ProductDetail .referenceImages .swiper-container .fa-search-plus {
  left: 90%;
  height: 21px;
}
body #ProductDetail .referenceImages .swiper-container .zoomSVG {
  position: absolute;
  left: 90%;
  bottom: 20px;
  z-index: 10;
  width: 20px;
  cursor: pointer;
}
body #ProductDetail .referenceImages .swiper-container .downloadSwiperImage {
  position: absolute;
  left: 95%;
  z-index: 10;
  bottom: 20px;
}
body #ProductDetail .referenceImages .swiper-container .swiper-slide:before {
  background-color: rgba(255, 255, 255, 0.7);
  content: "";
  height: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
body #ProductDetail .referenceImages .swiper-container .swiper-slide .js-fancybox {
  cursor: pointer;
}
body #ProductDetail .referenceImages .swiper-container .swiper-slide .photographer {
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
  color: #909090;
  background-color: #ffffff;
  padding-left: 20px;
}
body #ProductDetail .referenceImages .swiper-container .container {
  height: 100%;
}
body #ProductDetail .referenceImages .swiper-container .container .bottomRow {
  height: 20px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  /*.photographer {
                            .Size6_1_2();
                            color: @FontColor;
                            position: absolute;
                            bottom: 0px;
                            padding-left: 20px;
                            z-index: 2;
                        }*/
}
body #ProductDetail.shop .referenceImages .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -3px;
}
body #ProductDetail.shop .referenceImages .swiper-container .swiper-slide:before {
  content: "";
  display: none;
}
body #ProductDetail.shop .productInformation .hideDescription {
  display: none;
}
body #ProductDetail.shop .productInformation .showDescription {
  display: block;
}
body #ProductDetail.shop .productInformation .icon-header {
  margin-top: 2.5em;
}
body #ProductDetail.shop .productInformation .icon-header img {
  width: 25px;
}
body #ProductDetail.shop .productInformation .icon-header hr {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}
body #ProductDetail.shop .productInformation .icon-header h3 {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 16px;
}
body #ProductDetail.shop .productInformation .clear-filter-btn {
  position: relative;
  transform: translate(-100%);
  left: 100%;
  margin-top: 5px;
  width: 53px;
  /*background-color: #346fcd !important;*/
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown {
  position: relative;
  display: inline-block;
  width: 70%;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle {
  display: flex;
  align-items: center;
  min-height: 2.5em;
  cursor: pointer;
  background-color: #f4f4f4;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle.show,
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle:hover {
  background-color: #c3c6c8;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle.show .dropdown-arrow,
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle:hover .dropdown-arrow {
  background-color: #005eb8;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle h3 {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 16px;
  flex: 1;
  margin: 0;
  padding: 0 15px;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle .dropdown-arrow {
  flex: 0 0 10%;
  align-self: stretch;
  background-color: #346fcd;
  z-index: 1;
  color: white;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-toggle .dropdown-arrow img {
  position: relative;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.3);
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-hidden {
  position: absolute;
  width: 100%;
  display: none;
  background-color: #f4f4f4;
  border: 1px solid #c3c6c8;
  z-index: 10;
  cursor: pointer;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-hidden .option {
  display: flex;
  padding: 0 15px;
  align-items: center;
  width: 100%;
  min-height: 3em;
  border-bottom: 1px solid #c3c6c8;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-hidden .option:last-of-type {
  border: none;
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-hidden .option:not(.disabled):hover {
  background-color: rgba(150, 150, 150, 0.1);
}
body #ProductDetail.shop .productInformation .property-grid .prop-dropdown .m-dropdown-hidden .option.disabled {
  cursor: not-allowed;
}
body #ProductDetail .crossSellSlider .swiper-wrapper {
  margin-bottom: 30px;
}
body #ProductDetail .crossSellSlider .swiper-wrapper .swiper-slide .dzsparallaxer {
  background-color: white;
}
body #ProductDetail .crossSellSlider .swiper-wrapper .swiper-slide .dzsparallaxer .image-holder {
  position: relative;
}
body #ProductDetail .crossSellSlider .swiper-wrapper .swiper-slide .dzsparallaxer .image-holder .bottom-text {
  position: absolute;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  left: 0;
  bottom: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
}
body #ProductDetail .crossSellSlider .swiper-wrapper .swiper-slide .dzsparallaxer .image-holder .bottom-text .cs-name {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
body #ProductDetail .crossSellSlider .swiper-wrapper .swiper-slide .dzsparallaxer .go-to-product {
  line-height: 2.3em;
}
body #ProductDetail .crossSellSlider .swiper-pagination {
  bottom: 0;
}
body #zoomedImageWindow {
  display: none;
}
@media only screen and (min-width: 576px) {
  body #ProductDetail .referenceImages .swiper-container .fa-search-plus {
    left: 92%;
  }
  body #ProductDetail .referenceImages .swiper-container .zoomSVG {
    left: 92%;
  }
  body #ProductDetail .referenceImages .swiper-container .downloadSwiperImage {
    left: 96%;
  }
}
@media only screen and (min-width: 1200px) {
  body #ProductDetail .referenceImages .swiper-container .fa-search-plus {
    left: 94%;
  }
  body #ProductDetail .referenceImages .swiper-container .zoomSVG {
    left: 94%;
  }
  body #ProductDetail .referenceImages .swiper-container .downloadSwiperImage {
    left: 97%;
  }
}
body .shopProductInfo {
  margin-top: 20px;
  padding-top: 15px;
  transition: margin-top 0.05s ease-in-out;
}
body .shopProductInfo h3 {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 23px;
  letter-spacing: 0.46px;
  margin-bottom: 3px;
  line-height: 1em;
}
body .shopProductInfo hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
body .shopProductInfo .price,
body .shopProductInfo .currentCurrency {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 23px;
  letter-spacing: 0.46px;
  color: #00519e;
  font-size: 20px;
}
body .shopProductInfo .shippingSpan {
  color: #00519e;
}
.btn:not(.navbar-toggler) {
  background-color: #808080 !important;
  border-radius: 0;
  border: none;
  color: #fff !important;
}
.btn:not(.navbar-toggler):hover {
  background-color: #8d8d8d;
}
.add-to-cart-btn {
  position: relative;
  display: block;
  padding: .4em;
  background-color: #346fcd;
  color: white;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}
.add-to-cart-btn:hover {
  background-color: #004485;
}
.add-to-cart-btn.inline-block {
  display: inline-block;
}
input[type=text],
input[type=Email],
input[type=Password] {
  background-color: #e9eaeb;
  border: none;
}
input[type=text]:focus,
input[type=Email]:focus,
input[type=Password]:focus {
  background-color: #e9eaeb;
}
select.form-control {
  background-color: #e9eaeb;
  border: none;
}
select.form-control:focus {
  background-color: #e9eaeb;
}
#ReferenceFinderTeaser .k-input {
  background-color: transparent;
}
#ReferenceFinderTeaser .filter-wrapper .form-group .form-control {
  background-color: #e9eaeb;
  color: #000;
  border: none;
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
#ReferenceFinderTeaser .filter-wrapper .form-group .form-control:focus {
  border-color: #00519e;
}
#ReferenceFinderTeaser .filter-wrapper .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
main #referenceList #filterControls {
  float: right;
  padding-right: 0px;
}
main #referenceList #filterControls .selected {
  font-weight: bold;
}
main #referenceList #filterControls li {
  text-align: left;
  padding-left: 15px;
}
main #referenceList #filterControls li:first-child {
  border-bottom: 1px solid #c3c6c8;
  border-top: 1px solid #c3c6c8;
  padding: 5px 0 5px 15px;
  margin-bottom: 5px;
}
main #referenceList #filterControls li:last-child {
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 5px;
}
main #referenceList .cbp {
  float: left;
  min-height: 350px;
  padding: 0px;
}
main #referenceList .referenceInfo {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 0 0 10px;
}
main #referenceList .referenceInfo .referenceName {
  float: left;
  font-weight: bold;
  color: #434343;
}
main #referenceList .bookletDownloadWrapper {
  display: none;
}
@media only screen and (min-width: 992px) {
  main #referenceList #filterControls {
    float: right;
    padding-right: 0px;
  }
  main #referenceList #filterControls .selected {
    font-weight: bold;
  }
  main #referenceList #filterControls li {
    text-align: left;
    padding-left: 15px;
  }
  main #referenceList #filterControls li:first-child {
    border-bottom: 1px solid #c3c6c8;
    border-top: 1px solid #c3c6c8;
    padding: 5px 0 5px 15px;
    margin-bottom: 5px;
  }
  main #referenceList #filterControls li:last-child {
    border-bottom: 1px solid #c3c6c8;
    padding-bottom: 5px;
  }
  main #referenceList .cbp {
    float: left;
    min-height: 350px;
    padding: 0px;
  }
  main #referenceList .referenceInfo {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 5px 0 0 10px;
  }
  main #referenceList .referenceInfo .referenceName {
    float: left;
    font-weight: bold;
    color: #434343;
  }
  main #referenceList .bookletDownloadWrapper {
    float: right;
    padding: 10px 15px;
    display: block;
  }
  main #referenceList .bookletDownloadWrapper .downloadSVG {
    border-bottom: 1px solid #c3c6c8;
    padding: 5px 0 5px 15px;
    width: 100%;
    display: block;
  }
  main #referenceList .bookletDownloadWrapper .bookletDownloadList {
    padding: 10px 0 10px 15px;
    border-bottom: 1px solid #c3c6c8;
  }
  main #referenceList .bookletDownloadWrapper .bookletDownload {
    list-style: none;
  }
}
main .breadCrumbs {
  width: 100%;
  height: 40px;
  padding: 10px 0px 5px 30px;
  /*background-color: #f4f4f4;*/
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
main .breadCrumbs.abstracted {
  padding: 0;
  height: 20px;
}
main .breadCrumbs a {
  color: #434343;
}
main .breadCrumbs a:hover {
  text-decoration: none;
  color: #00519e;
}
main .breadCrumbs a:last-of-type {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
main #MediaItemList {
  height: 100%;
  /*.info:hover .InfoWrapper {
            max-height: 200px;
        }*/
}
main #MediaItemList .cbp {
  width: 100%;
  float: left;
  padding: 0px;
  /*background-color: #f4f4f4;*/
}
main #MediaItemList .cbp .cbp-wrapper-outer {
  overflow: visible;
}
main #MediaItemList .cbp .cbp-wrapper-outer .cbp-wrapper .cbp-item .cbp-item-wrapper .u-block-hover {
  height: 241px;
  /*.image {
                                    position: relative;
                                    display: block;
                                    height: auto;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }*/
}
main #MediaItemList .cbp .cbp-wrapper-outer .cbp-wrapper .cbp-item .cbp-item-wrapper .u-block-hover img {
  height: 100%;
}
main #MediaItemList .Info {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: auto;
  padding: 5px 0 0 10px;
  display: block;
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
main #MediaItemList .InfoWrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
main #MediaItemList .cbp-item-wrapper:hover .InfoWrapper {
  max-height: 200px;
}
main .categoryLogo {
  margin-left: 11px;
}
main .categoryLogo img {
  max-width: 215px;
  margin-bottom: 10px;
}
main .productInfoTextWrapper {
  border-bottom: solid 1px #c3c6c8;
  border-top: solid 1px #c3c6c8;
  padding: 0px 10px;
}
main .productInfoTextWrapper .productInfoText .borderDiv {
  border-top: 1px solid #c3c6c8;
  /*width: 235px;*/
  width: calc(100% + 20px);
  position: relative;
  right: 10px;
  padding-bottom: 5px;
}
main .productInfoTextWrapper .productInfoText > *:first-child {
  padding-top: 7px;
  /*border-bottom: solid 1px @Gray;*/
}
main .productInfoTextWrapper .productInfoText a {
  color: #00519e;
}
main #ReferenceDetail .lightgray {
  background-color: white;
}
main #ReferenceDetail #filterControls {
  padding-right: 0px;
  width: 100%;
  margin-top: 30px;
}
main #ReferenceDetail #filterControls h2 {
  font-family: "Frutiger 45 Light";
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0px;
}
main #ReferenceDetail #filterControls .selected {
  font-weight: bold;
}
main #ReferenceDetail #filterControls li {
  text-align: left;
  padding-left: 15px;
  border-bottom: 1px solid #c3c6c8;
  padding: 5px 0 5px 15px;
  margin-bottom: 5px;
}
main #ReferenceDetail #filterControls li p,
main #ReferenceDetail #filterControls li a {
  margin: 0 0 0 0;
}
main #ReferenceDetail .referenceSlider {
  margin-bottom: 10px;
}
main #ReferenceDetail .referenceSlider .topRow {
  height: 40px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  display: grid;
  align-content: center;
  z-index: 2;
}
main #ReferenceDetail .referenceSlider .topRow .stageBreadCrumb {
  padding-left: 30px;
}
main #ReferenceDetail .referenceSlider .topRow .stageBreadCrumb > a {
  color: #434343;
}
main #ReferenceDetail .referenceSlider .topRow .stageBreadCrumb .active {
  font-weight: bold;
}
main #ReferenceDetail .referenceSlider .fixedHeight {
  height: auto;
}
main #ReferenceDetail .referenceSlider .fixedHeight .container {
  position: absolute;
  top: 0px;
  height: 100%;
}
main #ReferenceDetail .referenceSlider .fixedHeight .container .bottomRow {
  height: 18px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0px;
  left: 0px;
}
main #ReferenceDetail .referenceSlider .swiperNav {
  color: rgba(255, 255, 255, 0.6);
}
main #ReferenceDetail .referenceSlider .swiper-button-prev {
  left: 25px;
}
main #ReferenceDetail .referenceSlider .swiper-button-prev::after {
  font-size: 50px;
}
main #ReferenceDetail .referenceSlider .swiper-button-next {
  right: 25px;
}
main #ReferenceDetail .referenceSlider .swiper-button-next::after {
  font-size: 50px;
}
main #ReferenceDetail .widthInherit {
  /*            overflow: hidden;*/
  width: inherit;
}
main #ReferenceDetail .spaceLeft {
  padding-left: 12.5px !important;
}
main #ReferenceDetail .spaceRight {
  padding-right: 12.5px !important;
}
main #ReferenceDetail .pictures {
  height: 414px;
}
main #ReferenceDetail .singlePicture {
  height: 100%;
  width: inherit;
}
main #ReferenceDetail .multiplePicture {
  width: inherit;
  /*            width: inherit;*/
}
main #ReferenceDetail .row {
  width: 100%;
}
main #ReferenceDetail .align {
  float: left;
  min-height: 350px;
}
main #ReferenceDetail .referenceInfo {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 0 0 10px;
}
main #ReferenceDetail .referenceInfo .referenceName {
  float: left;
  font-weight: bold;
  padding: 0px 10px 0 0;
  color: #434343;
}
main #ReferenceDetail .bookletDownloadWrapper {
  display: block;
}
main #ReferenceDetail .bookletDownloadWrapper .downloadSVG {
  border-bottom: 1px solid #c3c6c8;
  padding: 5px 0 5px 15px;
  width: 100%;
  display: block;
}
main #ReferenceDetail .bookletDownloadWrapper .bookletDownloadList {
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #c3c6c8;
}
main #ReferenceDetail .bookletDownloadWrapper .bookletDownload {
  list-style: none;
}
main #ReferenceDetail .text {
  padding: 15px 15px 15px 15px;
}
main #ReferenceDetail .margin {
  margin-top: 15px;
}
main #ReferenceDetail .gallery {
  display: none;
}
main #ReferenceDetail .align {
  min-height: 0px;
}
main #ReferenceDetail .customerFeedback {
  display: none;
}
main #ReferenceDetail .referenceFinder {
  display: none;
}
@media only screen and (min-width: 768px) {
  main #ReferenceDetail .referenceSlider .topRow {
    height: 30px;
  }
  main #ReferenceDetail .referenceSlider .fixedHeight {
    height: auto;
  }
  main #ReferenceDetail .referenceSlider .fixedHeight .container {
    height: 100%;
  }
}
@media only screen and (min-width: 992px) {
  main #ReferenceDetail .lightgray {
    background-color: #f4f4f4;
  }
  main #ReferenceDetail .referenceSlider {
    float: left;
  }
  main #ReferenceDetail .referenceSlider .fixedHeight {
    height: auto;
  }
  main #ReferenceDetail .referenceSlider .fixedHeight .container {
    height: 100%;
  }
  main #ReferenceDetail .rightSideWrapper {
    float: right;
  }
  main #ReferenceDetail .rightSideWrapper #filterControls {
    padding-right: 0px;
  }
  main #ReferenceDetail .rightSideWrapper #filterControls h2 {
    font-family: "Frutiger 45 Light";
    font-size: 16px;
    letter-spacing: 1.92px;
    margin-bottom: 0px;
  }
  main #ReferenceDetail .rightSideWrapper #filterControls .selected {
    font-weight: bold;
  }
  main #ReferenceDetail .rightSideWrapper #filterControls li {
    text-align: left;
    padding-left: 15px;
    font-family: "Frutiger 45 Light";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  main #ReferenceDetail .rightSideWrapper #filterControls li:first-child {
    border-bottom: 1px solid #c3c6c8;
    border-top: 1px solid #c3c6c8;
    padding: 10px 0 10px 15px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  main #ReferenceDetail .rightSideWrapper #filterControls li:last-child {
    border-bottom: 1px solid #c3c6c8;
    padding-bottom: 5px;
  }
  main #ReferenceDetail .rightSideWrapper .bookletDownloadWrapper {
    display: block;
  }
  main #ReferenceDetail .rightSideWrapper .bookletDownloadWrapper .downloadSVG {
    border-bottom: 1px solid #c3c6c8;
    padding: 5px 0 5px 15px;
    width: 100%;
    display: block;
  }
  main #ReferenceDetail .rightSideWrapper .bookletDownloadWrapper .bookletDownloadList {
    padding: 10px 0 10px 15px;
    border-bottom: 1px solid #c3c6c8;
  }
  main #ReferenceDetail .rightSideWrapper .bookletDownloadWrapper .bookletDownload {
    list-style: none;
  }
  main #ReferenceDetail .referenceInfo {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 5px 0 0 10px;
  }
  main #ReferenceDetail .referenceInfo .referenceName {
    float: left;
    font-weight: bold;
    padding: 0px 10px 0 0;
    color: #434343;
  }
  main #ReferenceDetail .gallery {
    display: flex;
  }
  main #ReferenceDetail .gallery .swiper-container {
    height: 100%;
  }
  main #ReferenceDetail .gallery .swiper-container .swiper-pagination {
    bottom: -5px;
    width: unset;
    right: 10px;
    left: unset;
  }
  main #ReferenceDetail .gallery .swiper-container .swiper-pagination-bullet {
    background-image: url('../../Media/Custom/inaktiv_TransBg.png');
    height: 12px;
    width: 12px;
    background-color: unset;
    opacity: 1;
  }
  main #ReferenceDetail .gallery .swiper-container .swiper-pagination-bullet-active {
    background-image: url('../../Media/Custom/aktiv_TransBg.png');
  }
  main #ReferenceDetail .gallery .swiper-container .swiper-slide a {
    height: 100%;
  }
  main #ReferenceDetail .gallery .galleryFilter {
    width: inherit;
  }
  main #ReferenceDetail .gallery .galleryBottomRow {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 237.5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  main #ReferenceDetail .text h1 {
    font-family: "Frutiger 67 Bold Condensed";
    font-size: 23px;
    letter-spacing: 0.46px;
  }
  main #ReferenceDetail .text p {
    font-family: "Frutiger 45 Light";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  main #ReferenceDetail .text .feedbackQuote {
    font-family: "Frutiger 48 Light Condensed Italic";
    font-size: 14px;
    letter-spacing: 0.28px;
  }
  main #ReferenceDetail .align {
    min-height: 350px;
  }
  main #ReferenceDetail .customerFeedback {
    display: block;
  }
  main #ReferenceDetail .referenceFinder {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  main #ReferenceDetail .referenceSlider .fixedHeight {
    height: auto;
  }
  main #ReferenceDetail .referenceSlider .fixedHeight .container {
    height: 100%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  main #search #search-results li .imageWrapper {
    width: calc(200%);
    position: relative;
    display: inline-block;
    height: auto;
    float: left;
  }
  main #search #search-results li .contentWrapper {
    padding-left: 20px;
    width: calc(-100%);
  }
  main #search #search-results li .buttonWrapper {
    position: absolute;
    bottom: 24px;
    height: auto !important;
    width: auto;
    left: 220px;
  }
  main #search #search-results li .buttonWrapper .btn {
    position: relative;
    left: 0px;
    bottom: auto;
  }
  main #search #search-results img {
    height: 200px;
    width: 200px;
    float: left;
    margin-top: 10px;
    margin-right: 25px;
    margin-bottom: 10px;
  }
}
main #search .wrapword {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap;
  /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}
main #search #filterControls {
  width: 100%;
  float: right;
  padding-right: 0px;
}
main #search #filterControls .selected {
  font-weight: bold;
}
main #search #filterControls li {
  text-align: left;
  padding-left: 15px;
}
main #search #filterControls li:first-child {
  border-bottom: 1px solid #c3c6c8;
  border-top: 1px solid #c3c6c8;
  padding: 5px 0 5px 15px;
  margin-bottom: 5px;
}
main #search #filterControls li:last-child {
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 5px;
}
main #search #filterControls .filterHeader {
  margin-bottom: 0px;
}
main #search #search-results li {
  position: relative;
  border-top: 1px solid #c3c6c8;
  padding: 15px 15px 15px 0px;
  min-height: 250px;
}
main #search #search-results li h3 {
  margin-top: 4px;
  margin-bottom: 0px;
}
main #search #search-results li h5 {
  margin-bottom: 25px;
}
main #search #search-results li p {
  margin-bottom: 25px;
}
main #search #search-results li .btn {
  position: absolute;
  bottom: 25px;
}
main #search #search-results li .imageWrapper {
  height: 200px;
  width: 200px;
}
main #search #search-results li .contentWrapper {
  padding-top: 20px;
}
main #search #search-results li .buttonWrapper {
  height: 42px;
}
main #search #search-results img {
  height: 200px;
  width: 200px;
  float: left;
  margin-top: 10px;
  margin-right: 25px;
  margin-bottom: 10px;
}
main #search .referenceFinderSmall {
  position: relative;
  top: 7px;
}
main #search .referenceFinderSmall h3:first-of-type {
  border-bottom: 1px solid #c3c6c8;
}
main #search .referenceFinderSmall .filter-wrapper {
  padding-top: 35px;
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 34px;
}
main #search .referenceFinderSmall .filter-wrapper input:focus {
  border-color: #00519e;
}
main #search .productFinderSmall {
  position: relative;
  top: 7px;
  margin-bottom: 40px;
}
main #search .productFinderSmall h3:first-of-type {
  border-bottom: 1px solid #c3c6c8;
}
main #search .productFinderSmall .filter-wrapper {
  padding-top: 35px;
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 34px;
}
main #search .productFinderSmall .filter-wrapper input:focus {
  border-color: #00519e;
}
main #search .productFinderSmall .form-group {
  width: 100%;
}
@media only screen and (min-width: 576px) {
  main #search #search-results li .imageWrapper {
    height: 220px;
    width: inherit;
    display: block;
  }
  main #search #search-results li .contentWrapper {
    padding: 0px;
  }
  main #search #search-results li .buttonWrapper {
    height: 42px;
  }
}
@media only screen and (min-width: 768px) {
  main #search #search-results li .imageWrapper {
    height: auto;
  }
}
@media only screen and (min-width: 992px) {
  main #search .productFinderSmall {
    margin-bottom: 0px;
  }
  main #referenceList #filterControls {
    float: right;
    padding-right: 0px;
  }
  main #referenceList #filterControls .selected {
    font-weight: bold;
  }
  main #referenceList #filterControls li {
    text-align: left;
    padding-left: 15px;
  }
  main #referenceList #filterControls li:first-child {
    border-bottom: 1px solid #c3c6c8;
    border-top: 1px solid #c3c6c8;
    padding: 5px 0 5px 15px;
    margin-bottom: 5px;
  }
  main #referenceList #filterControls li:last-child {
    border-bottom: 1px solid #c3c6c8;
    padding-bottom: 5px;
  }
}
main #teamList #filterControls {
  float: right;
  padding-right: 0px;
  padding-left: 0px;
}
main #teamList #filterControls .selected {
  font-weight: bold;
}
main #teamList #filterControls li {
  text-align: left;
  padding-left: 15px;
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
main #teamList #filterControls li:first-child {
  border-bottom: 1px solid #c3c6c8;
  border-top: 1px solid #c3c6c8;
  padding: 5px 0 5px 15px;
  margin-bottom: 5px;
  font-family: "Frutiger 45 Light";
  font-size: 16px;
  letter-spacing: 1.92px;
}
main #teamList #filterControls li:last-child {
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 5px;
}
main #teamList .cbp {
  float: left;
  padding: 0px;
}
main #teamList .cbp .hoverContainer {
  position: relative;
}
main #teamList .cbp .image {
  display: block;
  width: 100%;
  height: auto;
}
main #teamList .cbp .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
main #teamList .cbp .hoverContainer:hover .overlay {
  height: 100%;
}
main #teamList .cbp .imageOverlay {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
main #teamList .teamInfo {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 0 0 10px;
  height: auto;
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
main #teamList .teamInfo a {
  display: block;
}
main #teamList .teamInfo .teamName {
  float: left;
  color: #434343;
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
@media only screen and (min-width: 992px) {
  main #teamList #filterControls {
    float: right;
    padding-right: 0px;
    padding-left: 15px;
  }
  main #teamList #filterControls .selected {
    font-weight: bold;
  }
  main #teamList #filterControls li {
    text-align: left;
    padding-left: 15px;
  }
  main #teamList #filterControls li:first-child {
    border-bottom: 1px solid #c3c6c8;
    border-top: 1px solid #c3c6c8;
    padding: 5px 0 5px 15px;
    margin-bottom: 5px;
  }
  main #teamList #filterControls li:last-child {
    border-bottom: 1px solid #c3c6c8;
    padding-bottom: 5px;
  }
  main #teamList .cbp {
    float: left;
    min-height: 350px;
    padding: 0px;
  }
  main #teamList .teamInfo {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 5px 0 0 10px;
    height: auto;
  }
  main #teamList .teamInfo .teamName {
    float: left;
    color: #434343;
  }
  main #teamList .bookletDownloadWrapper {
    float: right;
    padding: 10px 15px;
    display: block;
  }
  main #teamList .bookletDownloadWrapper .downloadSVG {
    border-bottom: 1px solid #c3c6c8;
    padding: 5px 0 5px 15px;
    width: 100%;
    display: block;
  }
  main #teamList .bookletDownloadWrapper .bookletDownloadList {
    padding: 10px 0 10px 15px;
    border-bottom: 1px solid #c3c6c8;
  }
  main #teamList .bookletDownloadWrapper .bookletDownload {
    list-style: none;
  }
}
@media screen and (min-width: 768px) {
  .size-4 {
    bottom: 0px !important;
    transition: bottom 0.3s ease !important;
  }
  .size-3 {
    bottom: 0px !important;
    transition: bottom 0.3s ease !important;
  }
  .size-2 {
    bottom: 0px !important;
    transition: bottom 0.3s ease !important;
  }
  .size-1 {
    bottom: 0px !important;
  }
  .cbp-item-wrapper:hover .size-4 {
    bottom: -60px !important;
  }
  .cbp-item-wrapper:hover .size-3 {
    bottom: -40px !important;
  }
  .cbp-item-wrapper:hover .size-2 {
    bottom: -20px !important;
  }
}
body .multiCloseButton {
  padding: 5px;
  transform: translate(-100%, 0);
  left: 100%;
}
body .removePaddingBot {
  padding-bottom: 0px !important;
}
body #ShopTeaser .form-group .form-control,
body #NewsTeaser .form-group .form-control,
body #NewsletterTeaser .form-group .form-control {
  background-color: #e9eaeb;
  color: #000;
  border: none;
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
body #ShopTeaser .btn,
body #NewsTeaser .btn,
body #NewsletterTeaser .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body #ShopTeaser .btn-newsletter,
body #NewsTeaser .btn-newsletter,
body #NewsletterTeaser .btn-newsletter {
  margin-top: 57px;
}
body #ContactTeaser .k-input {
  background-color: transparent;
}
body #ContactTeaser .form-group .form-control {
  background-color: #e9eaeb;
  color: #000;
  border: none;
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
body #ContactTeaser .form-group .form-control:focus {
  border-color: #00519e;
}
body #ContactTeaser .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body #ContactTeaser:not(.TeaserBox) {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 10px !important;
}
body #ContactTeaser:not(.TeaserBox) .form-group {
  width: 100%;
  max-width: calc(100% - 100px);
  display: inline-block;
  height: 36px;
}
body #ContactTeaser:not(.TeaserBox) .form-group .form-control {
  background: #f4f4f4;
  color: #000;
  border: none;
  height: 36px;
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
body #ContactTeaser:not(.TeaserBox) .form-group .form-control .k-multiselect-wrap {
  background: #f4f4f4;
  height: 36px;
  width: calc(100% - 40px);
  display: flex;
  overflow: hidden;
}
body #ContactTeaser:not(.TeaserBox) .form-group .form-control .k-multiselect-wrap input {
  text-transform: uppercase;
}
body #ContactTeaser:not(.TeaserBox) .form-group .form-control .k-multiselect-wrap ul {
  display: flex;
  width: max-content;
  max-width: 100%;
}
body #ContactTeaser:not(.TeaserBox) .form-group .form-control .k-multiselect-wrap ul li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  flex: 1;
  max-width: 200px;
}
body #ContactTeaser:not(.TeaserBox) .form-group .form-control .k-multiselect-wrap ul li span:first-child {
  text-overflow: ellipsis;
  overflow: hidden;
}
body #ContactTeaser:not(.TeaserBox) .action-wrapper {
  min-width: 100px;
  display: flex;
  height: 36px;
  max-width: 100px;
}
body #ContactTeaser:not(.TeaserBox) .action-wrapper a {
  max-width: 100px;
  min-width: 100px;
}
body #ContactTeaser:not(.TeaserBox) .action-wrapper a text {
  margin-right: 5px !important;
}
@media screen and (max-width: 400px) {
  body #ContactTeaser:not(.TeaserBox) .form-group {
    width: 100%;
    max-width: calc(100% - 50px);
  }
  body #ContactTeaser:not(.TeaserBox) .action-wrapper {
    min-width: 50px;
    display: flex;
    height: 36px;
    max-width: 50px;
  }
  body #ContactTeaser:not(.TeaserBox) .action-wrapper a {
    max-width: 50px;
    min-width: 50px;
  }
  body #ContactTeaser:not(.TeaserBox) .action-wrapper a text {
    display: none;
  }
}
body #ContactTeaser:not(.TeaserBox) .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body .box_woTopPad #ContactTeaser {
  margin-top: 20px;
  padding-top: 15px;
}
body .box_woTopPad #ContactTeaser h3 {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 23px;
  letter-spacing: 0.46px;
}
body .box_woTopPad #ContactTeaser p {
  font-family: "Frutiger 45 Light";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.28px;
}
body .box_woTopPad #ContactTeaser .row input {
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body .box_woTopPad #ContactTeaser .row .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
  padding: 8px;
}
body .box_woTopPad #ContactTeaser .row .btn text {
  margin-right: 20px;
}
body .box #ContactTeaser .row input {
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
body .box #ContactTeaser .row .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body .newsText {
  height: 165px;
  overflow: inherit;
}
body .shopText {
  height: 172px;
  overflow: hidden;
}
body .newsletterText {
  min-height: 81px;
}
body .referenceFinderText {
  min-height: 81px;
}
body .gridTeaser .cbp-item.identity {
  width: 260px;
}
body .gridTeaser .teaserText {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  width: 260px;
  padding: 5px 0 0 10px;
}
body .gridTeaser .teaserText p {
  margin-bottom: 0;
}
body .TeaserBox {
  position: relative;
  min-height: 265px;
}
body .TeaserBox.ProductPage {
  min-height: 255px;
  padding-top: 15px;
}
body .TeaserBox .row {
  position: absolute;
  bottom: 0;
}
#NewsTeaser {
  min-height: 260px;
}
#newsListView .newsItemRow li,
#careerListView .newsItemRow li,
#newsListView .careerItemRow li,
#careerListView .careerItemRow li {
  position: relative;
  display: table;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #c3c6c8;
}
#newsListView .newsItemRow li:last-of-type,
#careerListView .newsItemRow li:last-of-type,
#newsListView .careerItemRow li:last-of-type,
#careerListView .careerItemRow li:last-of-type {
  border-bottom: 1px solid #c3c6c8;
}
#newsListView .newsItemRow li .imageWrapper,
#careerListView .newsItemRow li .imageWrapper,
#newsListView .careerItemRow li .imageWrapper,
#careerListView .careerItemRow li .imageWrapper {
  width: 20%;
  margin-right: 20px;
  float: left;
  min-width: 200px;
  min-height: 200px;
}
#newsListView .newsItemRow li .imageWrapper img,
#careerListView .newsItemRow li .imageWrapper img,
#newsListView .careerItemRow li .imageWrapper img,
#careerListView .careerItemRow li .imageWrapper img {
  max-width: 100%;
  max-height: 250px;
  height: auto;
}
#newsListView .newsItemRow h3,
#careerListView .newsItemRow h3,
#newsListView .careerItemRow h3,
#careerListView .careerItemRow h3 {
  margin-top: 4px;
  margin-bottom: 0px;
}
#newsListView .newsItemRow h5,
#careerListView .newsItemRow h5,
#newsListView .careerItemRow h5,
#careerListView .careerItemRow h5 {
  margin-bottom: 15px;
}
#newsListView .newsItemRow p,
#careerListView .newsItemRow p,
#newsListView .careerItemRow p,
#careerListView .careerItemRow p {
  margin-bottom: 15px;
}
#newsListView .newsItemRow .contentWrapper,
#careerListView .newsItemRow .contentWrapper,
#newsListView .careerItemRow .contentWrapper,
#careerListView .careerItemRow .contentWrapper {
  width: 75%;
  float: left;
  position: relative;
  bottom: 10px;
}
#newsListView .news-list-view,
#careerListView .news-list-view,
#newsListView .career-list-view,
#careerListView .career-list-view {
  border: none;
}
#newsListView .news-list-view *,
#careerListView .news-list-view *,
#newsListView .career-list-view *,
#careerListView .career-list-view * {
  box-sizing: border-box;
}
#newsListView .PagerWrapper #NewsPagination,
#careerListView .PagerWrapper #NewsPagination,
#newsListView .PagerWrapper #CareerPagination,
#careerListView .PagerWrapper #CareerPagination {
  border: none;
}
#newsListView .PagerWrapper .k-pager-wrap .k-link.k-state-disabled,
#careerListView .PagerWrapper .k-pager-wrap .k-link.k-state-disabled {
  color: #808080;
}
#newsListView .PagerWrapper .k-pager-numbers .k-state-selected,
#careerListView .PagerWrapper .k-pager-numbers .k-state-selected {
  color: #808080;
  border-color: #00519e;
}
#newsListView .PagerWrapper .k-link,
#careerListView .PagerWrapper .k-link,
#newsListView .PagerWrapper .k-nav-current.k-state-hover .k-link,
#careerListView .PagerWrapper .k-nav-current.k-state-hover .k-link {
  color: #808080;
}
#newsListView .PagerWrapper .k-link:hover,
#careerListView .PagerWrapper .k-link:hover {
  color: #00519e;
}
@media only screen and (max-width: 768px) {
  #newsListView .newsItemRow li .imageWrapper,
  #careerListView .newsItemRow li .imageWrapper,
  #newsListView .careerItemRow li .imageWrapper,
  #careerListView .careerItemRow li .imageWrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    float: left;
  }
  #newsListView .newsItemRow li .contentWrapper,
  #careerListView .newsItemRow li .contentWrapper,
  #newsListView .careerItemRow li .contentWrapper,
  #careerListView .careerItemRow li .contentWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  #newsListView .newsItemRow li .contentWrapper,
  #careerListView .newsItemRow li .contentWrapper,
  #newsListView .careerItemRow li .contentWrapper,
  #careerListView .careerItemRow li .contentWrapper {
    width: 60%;
    overflow: hidden;
    max-height: 163px;
  }
  #newsListView .newsItemRow li .buttonWrapper,
  #careerListView .newsItemRow li .buttonWrapper,
  #newsListView .careerItemRow li .buttonWrapper,
  #careerListView .careerItemRow li .buttonWrapper {
    left: 37.5%;
    margin-top: 15px;
    position: absolute;
    bottom: 15px;
  }
}
@media only screen and (min-width: 992px) {
  #newsListView .newsItemRow li .contentWrapper,
  #careerListView .newsItemRow li .contentWrapper,
  #newsListView .careerItemRow li .contentWrapper,
  #careerListView .careerItemRow li .contentWrapper {
    width: 60%;
  }
  #newsListView .newsItemRow li .buttonWrapper,
  #careerListView .newsItemRow li .buttonWrapper,
  #newsListView .careerItemRow li .buttonWrapper,
  #careerListView .careerItemRow li .buttonWrapper {
    left: 26.5%;
  }
}
@media only screen and (min-width: 1200px) {
  #newsListView .newsItemRow li .contentWrapper,
  #careerListView .newsItemRow li .contentWrapper,
  #newsListView .careerItemRow li .contentWrapper,
  #careerListView .careerItemRow li .contentWrapper {
    width: 75%;
  }
  #newsListView .newsItemRow li .buttonWrapper,
  #careerListView .newsItemRow li .buttonWrapper,
  #newsListView .careerItemRow li .buttonWrapper,
  #careerListView .careerItemRow li .buttonWrapper {
    left: 22%;
  }
}
body #careerDetail #careerForm {
  position: relative;
  border-width: 1px 0 0 0;
  border-color: #ccc;
  border-style: solid;
}
body #careerDetail #careerForm input,
body #careerDetail #careerForm textarea {
  background-color: unset;
  border: 1px solid #ccc;
}
body #careerDetail #careerForm .uploadHeightFix {
  padding: .6rem 1rem .6rem;
}
body #careerDetail #careerForm .textareaHeightFix {
  padding: 1.1rem 1rem .6rem;
}
body #careerDetail #careerForm .careerPrivacyLabel {
  line-height: 1.6em;
  letter-spacing: 0.05em;
}
body #careerDetail #careerForm #sendApplicationButton {
  float: left;
}
body #careerDetail #careerForm .validationMessage {
  padding: 8px 0 8px 8px;
  float: left;
  color: red;
  display: none;
}
body .successWrapper {
  display: none;
}
body .successWrapper .successMessage {
  color: green;
}
body #contactForm #contactComment,
body #catalogOrder #contactComment,
body #contactForm #catalogOrderComment,
body #catalogOrder #catalogOrderComment {
  background-color: #e9eaeb;
  border: none;
}
body #contactForm #sendContactForm,
body #catalogOrder #sendContactForm,
body #contactForm #sendCatalogOrder,
body #catalogOrder #sendCatalogOrder {
  float: right;
}
body #contactForm .emailValidation,
body #catalogOrder .emailValidation {
  display: none;
}
body #contactForm .infoRequiredFields,
body #catalogOrder .infoRequiredFields {
  float: right;
}
body #contactForm .validationMessage,
body #catalogOrder .validationMessage {
  color: red;
  display: none;
}
body #contactForm .completedMessage,
body #catalogOrder .completedMessage {
  display: none;
}
body #GMapMultipleMarkers {
  min-height: 365px;
}
main #consultantSearch .wrapword {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap;
  /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}
main #consultantSearch #consultant-list li {
  position: relative;
  border-top: 1px solid #c3c6c8;
  padding: 15px 15px 15px 0px;
  min-height: 250px;
}
main #consultantSearch #consultant-list li h3 {
  margin-top: 4px;
  margin-bottom: 0px;
}
main #consultantSearch #consultant-list li h5 {
  margin-bottom: 25px;
}
main #consultantSearch #consultant-list li p {
  margin-bottom: 25px;
}
main #consultantSearch #consultant-list li .btn {
  position: absolute;
  bottom: 25px;
}
main #consultantSearch #consultant-list li .imageWrapper {
  height: 200px;
  width: 200px;
}
main #consultantSearch #consultant-list li .contentWrapper {
  padding-top: 20px;
}
main #consultantSearch #consultant-list li .buttonWrapper {
  height: 42px;
}
main #consultantSearch #consultant-list img {
  height: 200px;
  width: 200px;
  float: left;
  margin-top: 10px;
  margin-right: 25px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 576px) {
  main #consultantSearch #consultant-list li .imageWrapper {
    height: 220px;
    width: inherit;
    display: block;
  }
  main #consultantSearch #consultant-list li .contentWrapper {
    padding: 0px;
  }
  main #consultantSearch #consultant-list li .buttonWrapper {
    height: 42px;
  }
}
@media only screen and (min-width: 768px) {
  main #consultantSearch #consultant-list li .imageWrapper {
    height: auto;
  }
}
#imageFinder .form-group .form-control {
  background-color: #e9eaeb;
  color: #000;
  border: 0;
  font-family: "Frutiger 48 Light Condensed Italic";
  font-size: 12px;
}
#imageFinder .form-group .form-control:focus {
  border-color: #00519e;
}
#imageFinder .btn {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 14px;
  letter-spacing: 0.28px;
}
body .quantity-js {
  position: relative;
  height: 40px;
  background-color: rgba(150, 150, 150, 0.1);
}
body .quantity-js .qu-minus,
body .quantity-js .qu-number,
body .quantity-js .qu-plus {
  height: 100%;
}
body .quantity-js .qu-minus:hover,
body .quantity-js .qu-number:hover,
body .quantity-js .qu-plus:hover {
  cursor: pointer;
}
body .quantity-js .qu-minus,
body .quantity-js .qu-plus {
  position: relative;
  width: 40px;
  user-select: none;
  z-index: 1;
}
body .quantity-js .qu-minus:hover,
body .quantity-js .qu-plus:hover {
  background-color: rgba(150, 150, 150, 0.1);
}
body .quantity-js .qu-minus .sign,
body .quantity-js .qu-plus .sign {
  position: relative;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
body .quantity-js .qu-minus {
  float: left;
}
body .quantity-js .qu-plus {
  float: right;
}
body .quantity-js .qu-number {
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0 40px;
}
body .quantity-js .qu-number input {
  width: 100%;
  height: 100%;
  background-color: unset;
  border: none;
  text-align: center;
}
body .quantity-js .qu-number input:hover {
  background-color: rgba(150, 150, 150, 0.1);
}
body .quantity-js .qu-number input:focus {
  background-color: rgba(150, 150, 150, 0.1);
  outline: none;
}
body .checkoutFormContainer form #stepFormProgress .g-color-primary {
  color: #00519e !important;
}
body .checkoutFormContainer form #stepFormProgress .g-brd-primary {
  border-color: #00519e !important;
}
body .checkoutFormContainer form #stepFormProgress .g-bg-primary--active.active,
body .checkoutFormContainer form #stepFormProgress .active .g-bg-primary--active {
  background-color: #00519e !important;
}
body .checkoutFormContainer form #stepFormProgress .active .g-color-white--parent-active {
  color: #fff !important;
}
body .checkoutFormContainer form #stepFormProgress .g-bg-primary--checked.g-checked,
body .checkoutFormContainer form #stepFormProgress .g-checked .g-bg-primary--checked {
  background-color: #fff !important;
}
body .checkoutFormContainer form #stepFormProgress .g-brd-primary--active.active,
body .checkoutFormContainer form #stepFormProgress .active .g-brd-primary--active {
  border-color: #00519e !important;
}
body .checkoutFormContainer form #stepFormSteps .u-btn-primary {
  background-color: #00519e !important;
}
body .checkoutFormContainer form #stepFormSteps .add-to-cart-btn {
  background-color: #00519e;
}
body .checkoutFormContainer form #stepFormSteps .u-block-hover:hover .g-color-primary--hover,
body .checkoutFormContainer form #stepFormSteps .g-color-primary--hover:hover {
  color: #fff !important;
}
body .checkoutFormContainer form #stepFormSteps .js-quantity .js-plus {
  margin-right: 5px;
}
body .checkoutFormContainer form #step2 .shippingAddressForm input.error,
body .checkoutFormContainer form #step2 .billingAddressForm2 input.error,
body .checkoutFormContainer form #step2 .shippingAddressForm select.error,
body .checkoutFormContainer form #step2 .billingAddressForm2 select.error {
  border: 1px solid;
}
body .checkoutFormContainer form #step2 .u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v4,
body .checkoutFormContainer form #step2 .u-check input[type="checkbox"]:checked + .u-check-icon-radio-v4,
body .checkoutFormContainer form #step2 .u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v4,
body .checkoutFormContainer form #step2 .u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v4,
body .checkoutFormContainer form #step2 .u-check input[type="radio"]:checked + .u-check-icon-checkbox-v4,
body .checkoutFormContainer form #step2 .u-check input[type="radio"]:checked + .u-check-icon-radio-v4,
body .checkoutFormContainer form #step2 .u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v4,
body .checkoutFormContainer form #step2 .u-check input[type="radio"]:checked + * .u-check-icon-radio-v4 {
  color: #00519e;
  border-color: #00519e;
}
body #profilePageForm .u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8,
body #registrationForm .u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8,
body #profilePageForm .u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8,
body #registrationForm .u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8,
body #profilePageForm .u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8,
body #registrationForm .u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8,
body #profilePageForm .u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8,
body #registrationForm .u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8,
body #profilePageForm .u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8,
body #registrationForm .u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8,
body #profilePageForm .u-check input[type="radio"]:checked + .u-check-icon-radio-v8,
body #registrationForm .u-check input[type="radio"]:checked + .u-check-icon-radio-v8,
body #profilePageForm .u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8,
body #registrationForm .u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8,
body #profilePageForm .u-check input[type="radio"]:checked + * .u-check-icon-radio-v8,
body #registrationForm .u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 {
  color: #00519e;
  border-color: #00519e;
}
body #profilePageForm .u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8 ::after,
body #registrationForm .u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8 ::after,
body #profilePageForm .u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8 ::after,
body #registrationForm .u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8 ::after,
body #profilePageForm .u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8 ::after,
body #registrationForm .u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8 ::after,
body #profilePageForm .u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8 ::after,
body #registrationForm .u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8 ::after,
body #profilePageForm .u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8 ::after,
body #registrationForm .u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8 ::after,
body #profilePageForm .u-check input[type="radio"]:checked + .u-check-icon-radio-v8 ::after,
body #registrationForm .u-check input[type="radio"]:checked + .u-check-icon-radio-v8 ::after,
body #profilePageForm .u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8 ::after,
body #registrationForm .u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8 ::after,
body #profilePageForm .u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 ::after,
body #registrationForm .u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 ::after {
  background-color: #00519e;
}
body #profilePageForm .input-group-append,
body #registrationForm .input-group-append {
  background-color: #e9eaeb;
}
body #profilePageForm .input-group-append .input-group-text,
body #registrationForm .input-group-append .input-group-text {
  border: none;
}
body #profilePageForm #profileAddressData .addressDropDownContainer,
body #registrationForm #profileAddressData .addressDropDownContainer {
  display: flex;
}
body #profilePageForm #profileAddressData .addressDropDownContainer .addressDropDown,
body #registrationForm #profileAddressData .addressDropDownContainer .addressDropDown {
  width: 98%;
}
body #profilePageForm #profileAddressData .addressDropDownContainer .deleteAddress,
body #registrationForm #profileAddressData .addressDropDownContainer .deleteAddress {
  margin: 2px 0px;
}
body #profileAddressData,
body #profileOrdersData {
  display: none;
}
body .right-icon-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 300px;
  top: 25%;
  right: 0;
  transform: translateX(100%) translateX(-65px);
  z-index: 999;
}
body .right-icon-bar a {
  color: white;
}
body .right-icon-bar a:hover {
  text-decoration: none;
}
body .right-icon-bar a:last-of-type .single-bar {
  margin-bottom: 0;
}
body .right-icon-bar a .single-bar {
  align-items: center;
  margin-bottom: 15px;
  padding: 7px 15px;
  background-color: gray;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
}
body .right-icon-bar a .single-bar:hover {
  transform: translateX(-200px);
}
body .right-icon-bar a .single-bar .icon {
  flex: 0 0 auto;
}
body .right-icon-bar a .single-bar .icon img {
  width: 30px;
  height: 30px;
}
body .right-icon-bar a .single-bar .icon img.phone {
  margin-left: 3px;
}
body .right-icon-bar a .single-bar .text {
  flex: 1 1 auto;
  padding-left: 20px;
}
.k-animation-container .k-list-scroller .k-list .k-item {
  cursor: pointer;
}
.k-animation-container .k-list-scroller .k-list .k-item.k-state-focused {
  border: 1px solid #00519e;
}
.k-animation-container .k-list-scroller .k-list .k-item.k-state-selected {
  background-color: #00519e;
  border-bottom: 1px solid #005eb8;
  color: #fff;
}
.k-animation-container .k-list-scroller .k-list .k-item.k-state-selected:hover {
  background-color: #005eb8;
}
.k-multiselect {
  border: none;
  width: 100%;
}
.k-multiselect.k-state-focused .k-multiselect-wrap {
  border-color: #00519e;
}
.k-multiselect.k-state-focused .k-multiselect-wrap .k-button {
  background-color: #00519e;
  color: #fff;
}
.k-multiselect .k-multiselect-wrap {
  /*background-color: @NewLightGray;*/
  background-color: #e9eaeb;
  border: none;
}
.k-multiselect .k-multiselect-wrap .k-button {
  line-height: 1.2em;
  padding: .8rem 2rem .6rem 1rem;
}
.k-multiselect .k-multiselect-wrap .k-button .k-select {
  display: inherit;
  margin-right: 3px;
}
.k-multiselect .k-multiselect-wrap .k-input {
  box-sizing: border-box;
  color: #000;
  line-height: 1.5em;
  padding: .8rem 1rem .6rem;
  text-indent: 0;
}
.k-multiselect .k-multiselect-wrap .k-i-close {
  font-size: 18px;
}
.stageHome .topRow {
  height: 30px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
}
.stageHome .bottomRow {
  height: 20px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.stageHome .teaser-box-wrapper {
  height: 250px;
}
.stageHome .teaser-box-wrapper .teaser-box {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 20px 0 20px;
  max-width: 470px;
  bottom: 25px;
  right: 0;
}
.stageHome .teaser-box-wrapper .teaser-box .summary {
  font-family: "Frutiger 47 Light Condensed";
  font-size: 20px;
  letter-spacing: 1.6px;
  margin-bottom: 0px;
}
.stageHome .teaser-box-wrapper .teaser-box h1 {
  font-family: "Frutiger 65 Bold";
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.72px;
  margin-bottom: 0px;
}
.stageHome .teaser-box-wrapper .teaser-box .description {
  font-family: "Frutiger 45 Light";
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
}
.stageHome .teaser-box-wrapper .teaser-box .btn {
  font-family: "Frutiger 45 Light";
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
}
.stageHome .swiper-pagination {
  width: unset;
  max-width: 100%;
  right: 0px;
  bottom: 20px;
  transform: translateY(100%);
  position: absolute;
}
.stageHome .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background-image: url('../../Media/Custom/inaktiv_weiß.png');
  background-color: unset;
  opacity: 1;
}
.stageHome .swiper-pagination .swiper-pagination-bullet:last-of-type {
  margin-right: 0;
}
.stageHome .swiper-pagination .swiper-pagination-bullet-active {
  background-image: url('../../Media/Custom/aktiv_weiß.png');
}
.stage .singleImage {
  height: 300px !important;
}
.stage .singleImageContainer {
  max-width: 440px;
}
.stage .swiper-container .topRow {
  height: 30px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  display: grid;
  align-content: center;
  z-index: 2;
}
.stage .swiper-container .topRow .stageBreadCrumb {
  padding-left: 30px;
}
.stage .swiper-container .topRow .stageBreadCrumb > a {
  color: #434343;
}
.stage .swiper-container .topRow .stageBreadCrumb .active {
  font-weight: bold;
}
.stage .swiper-container .swiper-slide img {
  width: 100%;
  width: stretch;
  /* Unprefixed */
}
.stage .swiper-container .swiperNav {
  color: rgba(255, 255, 255, 0.6);
}
.stage .swiper-container .swiper-button-prev {
  left: 25px;
}
.stage .swiper-container .swiper-button-prev::after {
  font-size: 50px;
}
.stage .swiper-container .swiper-button-next {
  right: 25px;
}
.stage .swiper-container .swiper-button-next::after {
  font-size: 50px;
}
.stage .swiper-container .fixedHeight {
  height: 300px;
}
.stage .swiper-container .fixedHeight .js-fancybox {
  cursor: pointer;
}
.stage .swiper-container .fixedHeight .container {
  height: 300px;
  position: absolute;
  top: 0px;
}
.stage .swiper-container .fixedHeight .container .photographer {
  display: none;
}
.stage .swiper-container .fixedHeight .container .bottomRow {
  height: 18px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.stage .swiper-container .fixedHeight .container .bottomRow .mobileReferenceTitle {
  display: block;
  position: absolute;
  bottom: -3px;
  z-index: 2;
  padding-left: 20px;
}
.stage .swiper-container .fixedHeight .container .teaser-box {
  display: none;
}
@media only screen and (min-width: 768px) {
  .stage .singleImage {
    height: 435px !important;
  }
  .stage .singleImageContainer {
    max-width: 638px;
  }
  .stage .swiper-container .fixedHeight {
    height: 423px;
  }
  .stage .swiper-container .fixedHeight .container {
    height: 423px;
    position: absolute;
    top: 0px;
  }
}
@media only screen and (min-width: 992px) {
  .stage .singleImage {
    height: 434px !important;
  }
  .stage .singleImageContainer {
    max-width: 638px;
  }
  .stage .swiper-container .swiper-button-prev {
    left: 35px;
  }
  .stage .swiper-container .swiper-button-prev::after {
    font-size: 80px;
  }
  .stage .swiper-container .swiper-button-next {
    right: 35px;
  }
  .stage .swiper-container .swiper-button-next::after {
    font-size: 80px;
  }
  .stage .swiper-container .swiper-pagination {
    display: none;
  }
  .stage .swiper-container .fixedHeight {
    height: 545px;
  }
  .stage .swiper-container .fixedHeight .container {
    height: 545px;
    position: absolute;
    top: 0px;
  }
  .stage .swiper-container .fixedHeight .container .topRow .stageBreadCrumb {
    padding-left: 30px;
  }
  .stage .swiper-container .fixedHeight .container .topRow .stageBreadCrumb > a {
    color: #434343;
  }
  .stage .swiper-container .fixedHeight .container .topRow .stageBreadCrumb .active {
    font-weight: bold;
  }
  .stage .swiper-container .fixedHeight .container .photographer {
    display: block;
    position: absolute;
    bottom: -3px;
    z-index: 2;
    padding-left: 20px;
  }
  .stage .swiper-container .fixedHeight .container .bottomRow .mobileReferenceTitle {
    display: none;
  }
  .stage .swiper-container .fixedHeight .container .teaser-box {
    display: block;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 25px 0 25px;
    position: relative;
    right: 50px;
    max-width: 356px;
    top: 15px;
  }
  .stage .swiper-container .fixedHeight .container .teaser-box .summary {
    font-family: "Frutiger 47 Light Condensed";
    font-size: 20px;
    letter-spacing: 1.6px;
    font-size: 17px;
    margin-bottom: 2px;
  }
  .stage .swiper-container .fixedHeight .container .teaser-box h1 {
    font-family: "Frutiger 65 Bold";
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.72px;
    margin-bottom: 0px;
    font-size: 32px;
    line-height: 35px;
  }
  .stage .swiper-container .fixedHeight .container .teaser-box .description {
    font-family: "Frutiger 45 Light";
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.48px;
    font-size: 32px;
    line-height: 35px;
  }
  .stage .swiper-container .fixedHeight .container .teaser-box .btn {
    font-family: "Frutiger 45 Light";
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.48px;
    position: relative;
    bottom: 0px;
    margin: 0px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .stage .singleImage {
    height: 545px !important;
  }
  .stage .singleImageContainer {
    max-width: 800px;
  }
  .stage .swiper-container .fixedHeight .container {
    height: 545px;
  }
  .stage .swiper-container .fixedHeight .container .teaser-box {
    left: 10px;
  }
}
body .k-window .k-window-title {
  font-family: "Frutiger 67 Bold Condensed";
  font-size: 16px;
  color: #434343;
}
body #loginWindow,
body #passwordRetrievalWindow {
  display: none;
}
body #loginWindow form,
body #passwordRetrievalWindow form {
  width: 515px;
}
body #loginWindow .form-group input,
body #passwordRetrievalWindow .form-group input {
  width: 485px;
}
body #profileAddressData .form-group .form-control-feedback,
body #profileAccountData .form-group .form-control-feedback,
body #passwordRetrieval .form-group .form-control-feedback,
body #passwordRetrievalWindow .form-group .form-control-feedback,
body #loginWindow .form-group .form-control-feedback,
body #accountDataGroup .form-group .form-control-feedback,
body #addressDataGroup .form-group .form-control-feedback {
  display: none;
}
#makingOfPage.makingOfWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}
#makingOfPage .imageRow {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#makingOfPage .makingOfItem {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1;
  flex: 1;
  min-width: 200px;
}
#makingOfPage .makingOfItem img {
  transition: all .5s ease;
  filter: brightness(100%);
  width: 100%;
}
#makingOfPage .makingOfItem:hover img {
  transform: scale(1.1);
  filter: brightness(70%);
}
#makingOfPage .makingOfItem .makingOfTextBoxWrapper {
  height: 100%;
  display: block;
}
#makingOfPage .makingOfItem .makingOfTextBoxWrapper .makingOfTextBox {
  width: 100%;
  min-height: 10%;
  flex-wrap: wrap;
  max-height: 50%;
  height: min-content;
  padding: 4px;
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgb(0,0,0,0.5);
  z-index: 3;
}
#makingOfPage .makingOfItem .makingOfTextBoxWrapper .makingOfTextBox .makingOfText {
  text-align: center;
  color: white;
  text-overflow: ellipsis;
}
.video-link {
  position: relative;
  float: left;
  cursor: pointer !important;
}
.video-link img {
  display: block;
}
.video-link:after,
.video-link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
}
.video-link:after {
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-left: 20px solid #fff;
  margin-top: -18px;
  margin-left: -10px;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  z-index: 2;
}
.video-link:before {
  margin-top: -34px;
  margin-left: -34px;
  width: 60px;
  height: 60px;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
  background: rgba(0, 0, 0, 0.42);
  z-index: 1;
}
.video-link:hover:before {
  background: rgba(0, 0, 0, 0.7);
}
*::selection {
  background: #00519e;
}
.u-go-to-v1:hover,
.u-go-to-v1:focus:hover {
  text-decoration: none;
  color: #fff;
  background-color: #00519e;
}
.mediaLinkSVGWrapper {
  border-bottom: 1px solid #c3c6c8;
  padding: 5px 0 5px 15px;
  width: 100%;
  display: block;
}
.mediaLink {
  list-style: none;
}
.mediaLinkList {
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #c3c6c8;
}
.mediaLinkSVG {
  width: 25px;
  display: block;
  height: 25px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 90.19 90.19" preserveAspectRatio="xMidYMid meet"><defs><style>.cls-1 {fill: #434343;}</style></defs><g transform="scale(1,1)"><path fill="#434343" d="m45.09,90.19C20.23,90.19,0,69.96,0,45.09S20.23,0,45.09,0s45.09,20.23,45.09,45.09-20.23,45.09-45.09,45.09m0-83.07C24.16,7.12,7.12,24.16,7.12,45.09s17.03,37.97,37.97,37.97,37.97-17.03,37.97-37.97S66.03,7.12,45.09,7.12" /><path fill="#434343" d="m37.39,26.12c-2.69-1.57-4.89-.31-4.89,2.81v31.41c0,3.12,2.22,4.4,4.92,2.86l27.11-15.48c2.71-1.55,2.72-4.1.03-5.68l-27.17-15.91Z" /></g></svg>');
}
